<template>
    <ts-loading-banner :loading="loading">
        <!-- BEGIN mailbox -->
        <div class="mailbox">
            <!-- BEGIN mailbox-sidebar -->
            <div class="mailbox-sidebar">
                <div
                    class="mailbox-sidebar-header d-flex justify-content-center"
                >
                    <b-button
                        v-b-toggle.emailNav
                        data-bs-toggle="collapse"
                        class="btn btn-inverse btn-sm me-auto d-block d-lg-none"
                    >
                        <i class="fa fa-cog"></i>
                    </b-button>
                    <router-link
                        to="/admin/notification/notification-templates"
                        class="btn btn-inverse ps-40px pe-40px btn-sm"
                    >
                        {{ $t("notificationTemplate.backToList") }}
                    </router-link>
                </div>
                <b-collapse
                    class="mailbox-sidebar-content collapse d-lg-block"
                    id="emailNav"
                >
                    <!-- BEGIN scrollbar -->
                    <vue-custom-scrollbar class="h-100">
                        <div class="nav-title tw-uppercase">
                            <b>{{ $t("notificationTemplate.parameter") }}</b>
                        </div>
                        <ul>
                            <li
															v-for="(p, index) in notParam"
															:key="index"
															v-clipboard:copy="p.param_name"
															v-clipboard:success="onCopy"
															class="tw-pl-3 hover:tw-bg-blue-800 hover:tw-text-white tw-rounded-tl-md tw-cursor-pointer"
														>
                                {{ p.param_name }}
                            </li>
                        </ul>
                    </vue-custom-scrollbar>
                    <!-- END scrollbar -->
                </b-collapse>
            </div>
            <!-- END mailbox-sidebar -->
            <!-- BEGIN mailbox-content -->
            <div class="mailbox-content">
                <div class="mailbox-content-header">
                    <!-- BEGIN btn-toolbar -->
                    <div class="btn-toolbar align-items-center">
                        <a-radio-group
                            v-model="model.not_type_id"
                            @change="onNotTypeChange"
                        >
                            <a-radio
                                v-for="(t, index) in notTypes"
                                :key="index"
                                :value="t.not_type_id"
                            >
                                {{ t.not_type }}
                            </a-radio>
                        </a-radio-group>
                    </div>
                    <!-- END btn-toolbar -->
                </div>
                <div class="mailbox-content-body">
                    <!-- BEGIN scrollbar -->
                    <vue-custom-scrollbar class="h-100">
                        <!-- BEGIN form -->
                        <form class="mailbox-form">
                            <!-- BEGIN mailbox-subject -->
                            <div class="mailbox-subject">
                                <input
                                    v-model="model.not_subject"
                                    type="text"
                                    class="form-control"
                                    :placeholder="$t('notificationTemplate.subject')"
																		:class="{'is-invalid': errors.has('not_subject')}"
                                />
																<div class="invalid-feedback" v-if="errors.has('not_subject')">
																		{{ errors.first('not_subject') }}
																</div>
                            </div>
                            <!-- END mailbox-subject -->
                            <!-- BEGIN mailbox-input -->
                            <div class="mailbox-input">
                                <VueEditor
                                    v-model="model.not_template"
                                    :editor-toolbar="customToolbar"
                                    v-bind:style="{
                                        height: editor.height + 'px'
                                    }"
                                ></VueEditor>
                            </div>
														<!-- <div class="validate"
															v-if="errors.has('not_template')"
														>
															{{ errors.first('not_template') }}
														</div> -->
                            <!-- END mailbox-input -->
                        </form>
                        <!-- END form -->
                    </vue-custom-scrollbar>
                    <!-- END scrollbar -->
                </div>
                <div
                    class="mailbox-content-footer d-flex align-items-center justify-content-end tw-space-x-3"
                >
                    <ts-button @click="()=> $router.push({ name: 'notification-template'})">{{ $t('cancel') }}</ts-button>
                    <ts-button color="primary" outline @click="onSaveAddNew" >{{ $t('saveAddNew') }}</ts-button>
                    <ts-button color="primary" @click="onSave">{{ $t('save') }}</ts-button>
                </div>
            </div>
            <!-- END mailbox-content -->
        </div>
        <!-- END mailbox -->
    </ts-loading-banner>
</template>

<script>
import { VueEditor } from "vue2-editor";
import AppOptions from "@/config/AppOptions.vue";
import customToolbar from "./customer-toolbar";
import { mapActions, mapState } from "vuex";
import { Errors } from  'form-backend-validation'
export default {
    components: {
        VueEditor
    },
    data() {
        AppOptions.appContentFullHeight = true;
        AppOptions.appContentClass = "p-0";
        return {
            loading: false,
            customToolbar: customToolbar,
						errors: new Errors(),
            editor: {
                height:
                    window.innerHeight > 600 ? window.innerHeight - 268 : 300
            },
            model: {
                not_type_id: "",
                not_subject: "",
                not_template: ""
            }
        };
    },
    computed: {
        ...mapState("notification/notificationTemplate", ["formModels"]),
        notTypes() {
            if (!this.formModels.notificationType) return [];
            return this.formModels.notificationType;
        },
        notParam() {
            if (!this.formModels.param) return [];
            return this.formModels.param;
        }
    },
    methods: {
        ...mapActions("notification/notificationTemplate", ["getFormViewData", 'store']),
        fetchFormView({ params, index }) {
            this.loading = true;
            this.getFormViewData({ params: params, index: index })
                .catch(err => {
                    this.$notify({ type: "error", text: err.message });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        onNotTypeChange(e) {
						this.fetchFormView({
                index: "param",
                params: {
                    not_type_id: e.target.value,
                    fnName: "param"
                }
            });
        },
				onSave(){
					this.loading = true
					this.store(this.model)
                .then(response => {
                    this.$notify({ type: "success", text: response.message });
										this.$router.push({ name: 'notification-template'})
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.$notify({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.loading = false;
                });
				},
				onSaveAddNew(){
					this.loading = true
					this.store(this.model)
                .then(response => {
                    this.$notify({ type: "success", text: response.message });
										this.clearInput()
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.$notify({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.loading = false;
                });
				},
				onCopy(e){
					this.$notify({ type: "success", text: 'Copied: ' + e.text });
				},
				clearInput(){
          this.model.not_type_id = ""
          this.model.not_subject = ""
          this.model.not_template = ""
				}
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.fetchFormView({ params: {}, index: undefined });
        });
    },
    beforeRouteLeave(to, from, next) {
        AppOptions.appContentFullHeight = false;
        AppOptions.appContentClass = "";
        next();
    }
};
</script>

<style>
/* .ql-editor {
    min-height: 23vh !important;
    height: 100% !important;
    overflow-y: scroll !important;
} */
</style>
